import React from "react"
import Navigation from "../components/Navigation"
import "../Styles/Dialogue.css"
import "./Loader.css"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import axios from 'axios'
import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import Counter from "../components/Counter"
//import Projects from "../components/Projects"
//import TestimonialSlider from "../components/TestimonialSlider"

 function StationDashboard() {

  const [user, setData] = useState(null);
  const [stations, setStation] = useState([]);
  const [categories, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete2, setItemToDelete2] = useState(null);
  const [itemToView, setItemToView] = useState(null);
  const [view, setView] = useState(null);
  const [wallet, setWallet] = useState('');
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [listings, setListings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredListings, setFilteredListings] = useState([]);

  const [isDialogOpenView, setIsDialogOpenView] = useState(false);

  //console.log(process.env.REACT_APP_IMAGE_URL);
 //  setUrl("https://api.locatefuel.com/storage/");

  const navigate = useNavigate();

  const handleLinkClick = () => {
    // Store the current scroll position
    const scrollPosition = window.scrollY;
   
    localStorage.setItem('scrollPosition', scrollPosition);
    
  };
  

  

  useEffect(() => {
    const fetchData = async () => {

      
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const user_role = localStorage.getItem('user_role'); // Get the token from localStorage
      //console.log(token);
      if(user_role === 'customer'){

        navigate('/dashboard');
        return;
      }
      if (!token) {
        
        //setError('No token found');
        navigate('/logout')
        return;
      }
      try {
        const token = localStorage.getItem('token'); // Get the token from localStorage
        const response3 = await fetch(`${process.env.REACT_APP_API_URL}get-wallet-detail`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response3.ok) {
          navigate('/login')
        }
        
        const wallet = await response3.json();
        //console.log(result);
        
        setWallet(wallet.data);
    
        //console.log(wallet.data.balance);
        
    
         
      } catch (error) {
        setError(error.message);
        navigate('/logout')
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}get-user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response.ok) {
          navigate('/logout')
        }
        
        const result = await response.json();
        //console.log(result);
        
        setData(result);
        
         
      } catch (error) {
        setError(error.message);
        navigate('/logout')
      }

      try {
        const response2 = await fetch(`${process.env.REACT_APP_API_URL}get-station-detail`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response2.ok) {
          navigate('/logout')
        }
        
        
        const result2 = await response2.json();

        //const station_logo = result2.data.station_logo;
        //console.log(station_logo);
        //setStation("https://api.locatefuel.com/storage/");

        

       // console.log(`https://imagelink.com/${station_logo}`);
        
        
        setStation(result2.data);
        setListings(result2.data);
        setFilteredListings(result2.data); // Initially, show all listings
       

        //const station_logo = result2.data.station_logo;
        // const station_banner = data.data.station_banner;
       
       return;
         
      } catch (error) {
        setError(error.message);
        navigate('/logout')
      }


      try {
        const token = localStorage.getItem('token'); // Get the token from localStorage
        const response3 = await fetch(`${process.env.REACT_APP_API_URL}get-wallet-detail`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response3.ok) {
          navigate('/login')
        }
        
        const wallet = await response3.json();
        //console.log(result);
        
        setWallet(wallet.data);
    
        //console.log(wallet.data.balance);
        
    
         
      } catch (error) {
        setError(error.message);
        navigate('/logout')
      }
      

     
      
    };

   

    fetchData();
  }, []);

  useEffect(() => {
    // Filter listings based on the search query
    const filtered = listings.filter(listing =>
      listing.station_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredListings(filtered);
  }, [searchQuery, listings]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  // const savedScrollPosition1 = localStorage.getItem('scrollPosition');
  //     if (savedScrollPosition1) {
  //       window.scrollTo(0, parseInt(savedScrollPosition1, 10));
        
  //     }

  const token = localStorage.getItem('token'); // Get the token from localStorage
  const handleDelete = () => {
    
    if (itemToDelete) {
      fetch(`${process.env.REACT_APP_API_URL}delete-station/${itemToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.ok) {
            setStation(stations.filter(station => station.id !== itemToDelete));
          } else {  
            console.error('Failed to delete item');
          }
        })
        .catch(error => console.error('Error deleting item:', error))
        .finally(() => {
          setIsDialogOpen(false);
          setItemToDelete(null);
        });
    }
  };

  const openDeleteDialog = (station) => {
    setItemToDelete(station);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setItemToDelete(null);
  };

  //delete category
  //const token = localStorage.getItem('token'); // Get the token from localStorage
  const handleDelete2 = () => {
    
    if (itemToDelete2) {
      //console.log(itemToDelete2);
      fetch(`${process.env.REACT_APP_API_URL}delete-category/${itemToDelete2}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response2 => {
          if (response2.ok) {
            //console.log(category);
            navigate('');

            setCategory(categories.filter(category => category.id !== itemToDelete2));
          } else {  
            console.error('Failed to delete item');
          }
        })
        .catch(error => console.error('Error deleting item:', error))
        .finally(() => {
          setIsDialogOpen2(false);
          setItemToDelete2(null);
        });
    }
  };

  const openDeleteDialog2 = (category) => {
    setItemToDelete2(category);
    setIsDialogOpen2(true);
  };

  const closeDialog2 = () => {
    setIsDialogOpen2(false);
    setItemToDelete2(null);
  };

  const openViewDialog = (station) => {

    
   // if (itemToView) {
      //console.log(itemToView);
      //console.log(station);
      fetch(`${process.env.REACT_APP_API_URL}get-station-detail/${station}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response3 => {
          if (response3.ok) {
           // console.log(response);
           // navigate('');
          
           setView(response3.data);

           console.log(view);
          
           
          } else {  
            console.error('Failed to display items');
          }
        })
        .catch(error => console.error('Error displaying item:', error))
        .finally(() => {
          setIsDialogOpenView(false);
          
        });
  //  }
    
    setIsDialogOpenView(true);
  };

  const closeDialogView = () => {
    setIsDialogOpenView(false);
    
  };


  
  //localStorage.removeItem('scrollPosition'); // Optional: Clean up

  //view wallet details
  
  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
  
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  if (!stations) {
  
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  
  

  return (
 <>
 <Header />
 
 <pre></pre>
 <pre></pre>
 
  <div className="" style={{ textAlign: 'left',  }}>
  
  
  
    <div className="container-fluid ">
      <div className="row row-group m-0 ">

        
      
      <div className="col-12 col-lg-6 col-xl-6 border-light">
        
        <br />
        <div className="card-body" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>

          <div style={{ color: 'black', marginBottom: '16px' }}>
            Welcome {user.data.name || 'friend'}
          </div>
          <h5 style={{ color: 'black', marginBottom: '16px' }}>
            ₦{wallet.balance} (Wallet)
            <span className="float-right"></span>
          </h5>
          <div className="progress my-3" style={{ height: "3px" }}>
            <div className="progress-bar" style={{ width: "99%", backgroundColor: '#D4AF37' /* Light Gold */ }}></div>
          </div>
          <p className="mb-0 small-font" style={{ color: 'black' }}>
            Your Account: {wallet.virtual_account_name} {wallet.virtual_account_number} {wallet.virtual_bank_name}
            <span className="float-right"></span>
          </p>
  
        </div>

        </div>
        <div className="col-12 col-lg-6 col-xl-6 border-light">
        <div className="card-body">
          <h5 className="text-white mb-0">Station Info<span className="float-right"><Link to="/add-station" className="btn btn-light btn-sm">+Add Station</Link></span></h5>
            <div className="progress my-3" style={{ height: "3px" }} >
               <div className="progress-bar" style={{ width:"99%" }}></div>
            </div>
          {/* <p className="mb-0 text-white small-font">Category 10 Max, Fuel <span className="float-right"><i className="zmdi zmdi-long-arrow-up"></i></span></p> */}

          <input className="form-control input-shadow"
            type="text" placeholder="Search listings..." value={searchQuery} onChange={handleSearchChange}
          />
        </div>
        
      </div>

     
      
    </div> 
      
   
    
    
      
      <div className="row mt-3">
      {filteredListings.map(station => (
        
      
        <div className="col-12 col-lg-6 col-xl-6 border-light">
        <div className="card-body" >
          <div className="card-content">
             <div className="card profile-card-2 col-lg-6 col-xl-12" >
              <div className="card-img-block">
                  <img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}/${station.station_banner}`} />
              </div>
              <div className="card-body pt-5">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}/${station.station_logo}` } alt="profile-image" className="profile" style={{ width: '100px', height: '75px' }} />
                  <h5 className="card-title" style={{ textTransform: 'capitalize' }}>{station.station_name} - {station.station_address} - {station.location}  </h5>
                  
                  
                  <button className="btn btn-danger btn-sm float-right" onClick={() => openDeleteDialog(station.id)}>Delete</button>
                  <span onClick={handleLinkClick}  className="float-left"><Link to={`../station-details/${station.id}`} className="btn btn-light btn-sm">More Details</Link></span><span></span>
              </div>
            </div>
          </div>
      </div>
        
      {isDialogOpenView && (
        <div className="dialog">
          <div className="dialog-content">
            <p className="" style={{color: 'black'}} > Details</p>
            <p className=""> Details</p>
            <button className="btn btn-success btn-sm" onClick={closeDialogView}>Cancel</button>
          </div>
        </div>
      )}
      {isDialogOpen && (
        <div className="dialog">
          <div className="dialog-content">
            <p className="" style={{color: 'black'}} >Are you sure you want to delete {station.station_name}?</p>
            <button className="btn btn-danger btn-sm" onClick={handleDelete}>Yes, Delete</button>
            <button className="btn btn-success btn-sm" onClick={closeDialog}>Cancel</button>
          </div>
        </div>
      )}
      

        
      </div>
  
        ))}
    </div>
   
  </div>
  
  </div>  


   
  
    </>
  )
}

export default (StationDashboard);
