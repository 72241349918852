import React from "react"
import Navigation from "../components/Navigation"
import "../Styles/Dialogue.css"
import "../Styles/ViewFuel.css"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import axios from 'axios'
import  { useEffect, useState } from 'react';
import {useParams, useNavigate } from 'react-router-dom';


//import Counter from "../components/Counter"
//import Projects from "../components/Projects"
//import TestimonialSlider from "../components/TestimonialSlider"

 function StationDashboard() {

  const [user, setData] = useState(null);
  const [stations, setStation] = useState([]);
  const [categories, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [view, setView] = useState(null);
  const [wallet, setWallet] = useState('');
  const { id } = useParams();
  const { user_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState('');
  const [availability, setAvailability] = useState('');

  const [isDialogOpenView, setIsDialogOpenView] = useState(false);

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  //console.log(process.env.REACT_APP_IMAGE_URL);
 //  setUrl("https://api.locatefuel.com/storage/");
 const token = localStorage.getItem('token'); // Get the token from localStorage

  const navigate = useNavigate();

  const handleGoBack = () => {
    
    navigate('/dashboard');
  }
 
    const fetchData = async () => {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      //console.log(token);

      
       
      
      if (!token) {
        
        //setError('No token found');
        navigate('../logout')
        return;
      }
      
      
      

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}view-station-detail/${id}/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response.ok) {
          navigate('../dashboard')
        }
        
        
        const result = await response.json();

        //const station_logo = result2.data.station_logo;
        //console.log(station_logo);
        //setStation("https://api.locatefuel.com/storage/");

        

       // console.log(`https://imagelink.com/${station_logo}`);
        
        
        setStation(result.data);
        setCategory(result.data);

        
        if (result.data && result.data[0] && result.data[0].category && result.data[0].category[0]) {
          setPrice(result.data[0].category[0].fuel_price || '');
          setAvailability(result.data[0].category[0].fuel_status || '');
      } else {
          console.log("Category data is missing");
          setPrice('');
          setAvailability('');
      }

        //const station_logo = result2.data.station_logo;
        // const station_banner = data.data.station_banner;
       
       return;
         
      } catch (error) {
        setError(error.message);
        console.log(setError)
        navigate('/logout')
      }


      
     
      
    };

    useEffect(() => { 

    fetchData();
  }, []);
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
};

const handleCloseModal = () => {
    setIsModalOpen(false);
};

const handleUpdate = async () => {
    // Update the record in the API
    const response = await fetch(`${process.env.REACT_APP_API_URL}update-fuel-price`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            price,
            id,
            availability,
        }),
    });

    if (response.ok) {
      const result = await response.json();
        alert(result.data);
        handleCloseModal(); // Close the modal on success
        fetchData(); // Refresh the data by calling fetchData
    } else if(!response.ok) {
      const result = await response.json();
        alert(result.message);
    }
};
  
  //delete category
  //const token = localStorage.getItem('token'); // Get the token from localStorage
  

  
  if (error) {
    return <div>{error}</div>;
  }

  
  
  

  return (
 <>
 <Header />
 
 <pre></pre>
 <pre></pre>
  <div class="loader-wrapper"><div class="lds-ring"></div></div>
  <div className="" style={{ alignItems: 'center'}}>
  
  
  
    <div className="container-fluid ">
      
      
      <div className="row mt-3" >
     
      {stations.map(station => ( 
      
        <div className="col-12 col-lg-6 col-xl-6 border-light" >
        <div className="card-body" >
          <div className="card-content">
             <div className="card profile-card-2 col-lg-6 col-xl-12" >
              <div className="card-img-block">
                  <img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}/${station.station.station_banner}`} />
              </div>
              <div className="card-body pt-5">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}/${station.station.station_logo}` } alt="profile-image" className="profile" style={{ width: '100px', height: '75px' }} />
                  <h5 className="card-title" style={{ textTransform: 'capitalize' }}>{station.station.station_name} - {station.station.station_address} - {station.station.location}  </h5>
              </div>
            </div>
          </div>
      </div>
        
     

        <div className="col-lg-12">
           <div className="card">
            <div className="card-body">
            
               
                    <a href="#" data-bs-target="#profile" data-bs-toggle="pill" className="nav-link active"><i className="bi bi-fuel-pump"></i> <span className="hidden-xs">Cateory for {station.station_name} </span></a>
              

                    
             {categories.map(category => ( 
                
            <div className="tab-content p-2" >
                <div className="tab-pane active" id="profile" >
                  
                    <h5 className="mb-2">{station.station.station_name} - {category.category[0].fuel_type}</h5>

                    <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                      <div className="col-12 col-lg-12 col-xl-12 border-light">
                          <div className="card-body">
                            <h5 className="text-white mb-0">{category.category[0].fuel_type} <span className="float-right">{category.category[0].fuel_status === 'Available'? <i className="fa fa-power-off" style={{backgroundColor: "green"}}> </i>: category.category[0].fuel_status === 'Not Available'? <i className="fa fa-power-off" style={{backgroundColor: "red"}}> </i>:''} {category.category[0].fuel_status}</span></h5>
                              <div className="progress my-3" style={{ height:"3px" }}>
                                 <div className="progress-bar" style={{ width:"99%" }}></div>
                              </div>
                              <p className="mb-0 text-white small-font" style={{ textTransform: 'capitalize' }}>
                                  {category.category[0].map_link ? (
                                    <a
                                      href={isMobileDevice() ? `geo:${category.category[0].map_link}` : `${category.category[0].map_link}`}
                                      target={isMobileDevice() ? '_self' : '_blank'}
                                      rel="noopener noreferrer"
                                    >
                                      <button className="badge btn btn-light btn-sm">Get Direction</button>
                                    </a>
                                  ) : null}
                                  <span className="float-right">₦{category.category[0].fuel_price}/{category.category[0].price_unit}</span>
                                </p>
                            <p className="mb-0 text-white small-font">Discount: {category.category[0].discount_percentage} % <span className="float-right">Class: {category.category[0].category_name}</span> </p>
                            <br/>
                            <span className="badge badge-light" onClick={handleOpenModal}>Update Fuel Availability</span>
                            <hr />
                            <span className="float-left"><button onClick={handleGoBack} className="btn btn-light btn-sm" >Back to Dashboard</button></span><span></span>
                              
                            
                          
                          </div>
                        
                      </div>
                      
                  </div>   
                </div>

                
                
            </div> 

            ))}

        </div>
      </div>
      </div>
      </div>
      ))}
    </div>
   
  </div>
  
  </div>  

      {/* Popup Form */}
      {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Update Availability</h2>
                        <label>
                            Price:
                            <input className="form-control input-shadow number-input"
                                type="number" 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                            />
                        </label>
                        <label>
                          Fuel Availability:
                        
                        <div className="position-relative has-icon-right">
                          <select className="form-control input-shadow" name="availability"   onChange={(e) => setAvailability(e.target.value)} >
                              <option vallue={availability}>{availability}</option>
                              <option vallue="Available">Available</option>
                              <option vallue="Not Available">Not Available</option>
                              
                              
                          </select>
                          
                          <div className="form-control-position">
                            <i className=""></i>
                          </div>
                        </div>
                        </label>
                        <button className="btn btn-dark btn-sm" onClick={handleUpdate}>Submit</button><br />
                        <button className="btn btn-danger btn-sm" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            )}
      
   
  
    </>
  )
}

export default (StationDashboard);
