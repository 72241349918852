import React from "react";
//import Navigation from "../components/Navigation2";
//import Counter from "../components/Counter";
import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import  {useMemo, useState, useEffect  } from 'react';
import {useParams, useNavigate } from 'react-router-dom';



    


    const MyForm = () => {
     
      const [error, setError] = useState(null);
      const [formErrors, setFormError] = useState(null);
      const [success, setSuccess] = useState(false);
      const [token, setToken] = useState('');
      const [fuel_type, setFuelType] = useState('');
      const [activate_status, setStatus] = useState('');
      const [fuel_status, setFuelStatus] = useState('');
      const [fuel_price, setFuelPrice] = useState('');
      const [phone_number, setPhoneNumber] = useState('');
      const [station_rep, setStationRep] = useState('');
      const [station_name, setStationName] = useState('');
      const [firstname, setFirstname] = useState('');
      const [station_id, setStationId] = useState('');
      const [price_unit, setPriceUnit] = useState('');
      const [category_name, setCategoryName] = useState('');
      const [options, setOptions] = useState('');
      const { id } = useParams();
      
      
      
    
      // Load data from localStorage when the component mounts
      useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('token'); // Get the token from localStorage
          const firstname = localStorage.getItem('firstname'); // Get the token from localStorage
          
         
         
          
          
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}get-station-detail/${id}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            //console.log(response);
            if (!response.ok) {
              navigate('/login')
            }
            
            const station = await response.json();
            //console.log(result);
            
            setOptions(station.data);

           
           
          } catch (error) {
            setError(error.message);
          }

          window.scrollTo(0, 0);
          //console.log(email);
          if (!token) {
            
            setError('No token found');
            navigate('/login')
            return;
          }
          
         
      
          
        }

        fetchData();
      },
      [id]);

      
      
      
      
       // Validate fields
   
          
       const navigate = useNavigate(); 
      
    
    //   const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    //   };

      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token'); // Get the token from localStorage
        const formData = {
          fuel_price,
          fuel_status,
          fuel_type,
          phone_number,
          station_rep,
          station_id:id,
          price_unit,
          category_name,
          activate_status,
          
        };
        try {
         //console.log(email);
        
          const response = await fetch(`${process.env.REACT_APP_API_URL}add-fuel-category`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            const result = await response.json();
            
             setError(result.message.email || result.message ||

               'Something went wrong');
              
                return;
            
            
          }
    
          const data = await response.json();
          //const token = data.data.token;
          //console.log(token);
          //localStorage.setItem('token', token);
          
          navigate(`/station-details/${data.data.station_id}`);
         
          setError(null);
          setSuccess(true);
        } catch (error) {
          setError(error.message);
          setSuccess(false);
        }
        
        
      };
  
    
      
    
   

  return (
    <>
      <div className="position-relative p-0">
        {/* <Navigation /> */}

        <div class="loader-wrapper"><div class="lds-ring"><div>
        <div class="card card-authentication1 mx-auto my-5">
          <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src="https://app.locatefuel.com/assets/images/logo-lf.png" alt="logo icon" />
             
            </div>
            <div class="card-header text-center py-4">
              <p class="text-white mb-0">Dear {firstname} </p>
            </div>
            <div class="card-title text-uppercase text-center py-3">Add Fuel Category</div>
              <form onSubmit={handleSubmit} id="MyForm" >
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Station Name</label>
              <div className="position-relative has-icon-right">
                
                <select className="form-control input-shadow" name="station_name" value={options.id} onChange={(e) => setStationId(e.target.value)}>
                    
                  <option value={options.id}>{options.station_name}</option>
                 
                     
                </select>
                
                <div className="form-control-position">
                 
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Fuel Type</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="fuel_type" value={fuel_type.fuel_type} onChange={(e) => setFuelType(e.target.value)}>
                    <option vallue="">Select Fuel Type</option>
                    <option vallue="Petrol">Petrol</option>
                    <option vallue="Diesel">Diesel</option>
                    <option vallue="Gas">LPG Gas</option>
                </select>
                
                <div className="form-control-position">
                 
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Fuel Status</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="fuel_status" value={fuel_status.fuel_status} onChange={(e) => setFuelStatus(e.target.value)}>
                    <option vallue="">Select Fuel Status</option>
                    <option vallue="Available">Available</option>
                    <option vallue="Not Available">Not Available</option>
                    
                    
                    
                </select>
                
                <div className="form-control-position">
                  
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Fuel Price</label>
              <div class="position-relative has-icon-right">
                <input type="number" id="" name="fuel_price"  value={fuel_price.fuel_price} onChange={(e) => setFuelPrice(e.target.value)} class="form-control input-shadow" placeholder="Enter Price Per Lt,Kg" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Price Unit</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="price_unit" value={fuel_type.price_unit} onChange={(e) => setPriceUnit(e.target.value)}>
                    <option vallue="">Select Price Unit</option>
                    <option vallue="Ltr">Ltr</option>
                    <option vallue="Kg">Kg</option>
                   
                </select>
                
                <div className="form-control-position">
                  
                </div>
              </div>
              </div>
              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Phone Number</label>
              <div class="position-relative has-icon-right">
                <input type="number" id="" name="Phone Number"  value={phone_number.phone_number} onChange={(e) => setPhoneNumber(e.target.value)} class="form-control input-shadow" placeholder="Enter Phone Number" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>

              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Station Classification</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="category_name" value={category_name.category} onChange={(e) => setCategoryName(e.target.value)}>
                    <option vallue="">Select Class</option>
                    <option vallue="Free">Free</option>
                    <option vallue="Premium">Premium</option>
                </select>
                
                <div className="form-control-position">
                  <i className=""></i>
                </div>
              </div>
              </div>

              <div class="form-group">
              <label for="exampleInputUsername" class="sr-only">Person Incharge</label>
              <div class="position-relative has-icon-right">
                <input type="text" id="" name="station_rep"  value={station_rep.station_rep} onChange={(e) => setStationRep(e.target.value)} class="form-control input-shadow" placeholder="Person In-Charge" />
                {/* <input type="hidden" id="email" name="email"  value={localStorage.getItem('email')} onChange={handleChange} class="form-control input-shadow" /> */}
                <div class="form-control-position">
                  <i class="icon-count"></i>
                </div>
              </div>
              </div>
              <div className="form-group">
              <label for="exampleInputUsername" className="sr-only">Activate For User</label>
              <div className="position-relative has-icon-right">
                <select className="form-control input-shadow" name="activate_status" value={activate_status.status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Select Status</option>
                    <option value="ACTIVE">ACTIVATE</option>
                    <option value="INACTIVE">DON'T ACTIVATE</option>
                    
                    
                    
                </select>
                
                <div className="form-control-position">
                  
                </div>
              </div>
              </div>
             
              
              
          
            <button type="submit" className="btn btn-light btn-block">+Add</button>
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Station Added Successful</div>}
            
            
              <div class="text-center mt-5">NOTE!</div>
           
            
            </form>
            </div>
            </div>
            <div class="card-footer text-center py-2">
              <p class="text-white mb-0"><i>Kindly ensure your fill your current price</i> </p>
            </div>
            </div>
          
          
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
          </div>
          </div>
          </div>
          </div>
    </>
  );
  
  }

// const Login = () => {
//   return (
//     <div>
      
//       <MyForm />
//     </div>
//   );
// };

export default MyForm;
