import React from "react"
import Navigation from "../components/Navigation"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import "./Loader.css"
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import axios from 'axios'
import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

//import Counter from "../components/Counter"
//import Projects from "../components/Projects"
//import TestimonialSlider from "../components/TestimonialSlider"

 function HomePage() {
  
  const [user, setData] = useState(null);
  const [wallet, setWallet] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [code, setCode] = useState(null);
  const [subscribe, setSubscribe] = useState();
  const [status, setStatus] = useState();
  const [s_lat, setSLat] = useState();
  const [s_long, setSlong] = useState();
  const [point, setPoint] = useState();
  const [petrols, setPetrol] = useState([]);
  const [petrols2, setPetrol2] = useState([]);
  const [diesels, setDiesel] = useState([]);
  const [diesels2, setDiesel2] = useState([]);
  const [gases, setGas] = useState([]);
  const [gases2, setGas2] = useState([]);

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [errorMessage, setErrorMessage] = useState('');
  

//   const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const toRadians = (degrees) => degrees * (Math.PI / 180);
//     const R = 6371e3; // Radius of the Earth in meters
//     const φ1 = toRadians(lat1);
//     const φ2 = toRadians(lat2);
//     const Δφ = toRadians(lat2 - lat1);
//     const Δλ = toRadians(lon2 - lon1);

//     const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
//               Math.cos(φ1) * Math.cos(φ2) *
//               Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//     return R * c; // Distance in meters
// };


  const getLocation = async (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const userLatitude = position.coords.latitude;
                const userLongitude = position.coords.longitude;
                setLocation({ latitude: userLatitude, longitude: userLongitude });
                  const latitude = userLatitude;
                  const longitude = userLongitude;
                  const formData = {

                    latitude,
                    longitude

                  }

                  const token = localStorage.getItem('token'); // Get the token from localStorage
        
                 //console.log(email);
                 
                  const response = fetch(`${process.env.REACT_APP_API_URL}get-location`, {
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                  });
            
                  if (response.ok) {
                    const result =  response.json();
                    
                     setError(result.message.email || result.message ||
        
                       'Something went wrong');
                      
                        return;
                    
                    
                  }
            
                  //const data =  response.json();

                

                // Calculate distance for each petrol station and sort by distance
                // const sortedPetrols = petrols.map(petrol => {
                //     const distance = calculateDistance(
                //         userLatitude,
                //         userLongitude,
                //         petrol.latitude,
                //         petrol.longitude
                //     );
                //     return { ...petrol, distance };
                // }).sort((a, b) => a.distance - b.distance);

                // setPetrol(sortedPetrols); // Update state with sorted petrol stations
            },
            (error) => {
                setErrorMessage('Unable to retrieve your location');
            }
        );
    } else {
        setErrorMessage('Geolocation is not supported by this browser');
    }
};

   

  const navigate = useNavigate();


  const city2 = localStorage.getItem('city'); // Get the token from localStorage
  const email_verify = localStorage.getItem('email_verify'); // Get the token from localStorage
  //console.log(email_verify);
  
  if(email_verify === null){

    navigate('/login');

  }

   const user_role = localStorage.getItem('user_role');
   if(user_role === 'provider'){

    navigate('/station-dashboard');
    
   }

    // const handleLinkClick = () => {
    //   // Store the current scroll position
    //   const scrollPosition = window.scrollY;
     
    //   localStorage.setItem('scrollPosition', scrollPosition);
      
    // };

    const handleCopyAccountNumber = () => {
      navigator.clipboard.writeText(wallet.virtual_account_number);
      alert('Account number copied to clipboard!');
    };
    

    const savedScrollPosition1 = localStorage.getItem('scrollPosition');
        if (savedScrollPosition1) {
          window.scrollTo(0, parseInt(savedScrollPosition1, 10));
          //localStorage.removeItem('scrollPosition'); // Optional: Clean up
        }

        

  useEffect(() => {
    const fetchData = async () => {

      
      const token = localStorage.getItem('token'); // Get the token from localStorage
      //const city = localStorage.getItem('city'); // Get the token from localStorage
      //console.log(token);
      if (!token) {
        
        //setError('No token found');
        navigate('/login')
        return;
      }

      

      const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
          sessionStorage.removeItem('scrollPosition'); // Clean up after restoration
        }
      
     // window.scrollTo(0, 0);
     try {
      // Create a fetch promise for each URL with method and headers
      const urls = [
        `${process.env.REACT_APP_API_URL}list-station-user/${city2}/Petrol`,
        `${process.env.REACT_APP_API_URL}list-station-user/${city2}/Diesel`,
        `${process.env.REACT_APP_API_URL}list-station-user/${city2}/Gas`,
        `${process.env.REACT_APP_API_URL}get-wallet-detail`,
        `${process.env.REACT_APP_API_URL}point-wallet`,
        
      ];
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const [response1, response2, response3, response4, response5] = await Promise.all(urls.map(url =>
        fetch(url, {
          method: 'GET', // Use 'GET', 'PUT', 'DELETE', etc., as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
         // Include body if needed (omit for GET requests)
        }).then(response => {
          if (!response.ok) {
            throw new Error(`Login Again: ${url}`);
            navigate('/login')
          }
          return response.json(); // or .text() or .blob() depending on the response type
          
          
        })
      ));

      setPetrol(response1.data.premium);
      setPetrol2(response1.data.free);
      setDiesel(response2.data.premium);
      setDiesel2(response2.data.free);
      setGas(response3.data.premium);
      setGas2(response3.data.free);
      setWallet(response4.data);
      setPoint(response5.data);
      //console.log('Data from API 1:', response1);
      // Wait for all promises to resolve
     // const results = await Promise.all(promises);
      
      //console.log('Fetched data:', results1);
    } catch (error) {
      //console.error('Error fetching data:', error);
      navigate('/login')
      return null; // or handle the error as needed
    }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}get-user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response.ok) {
          navigate('/login')
        }
        
        const result = await response.json();
        //console.log(result);
        
        setData(result.data);

        const city = result.data.city;

        //localStorage.setItem('city', city);
        
         
      } catch (error) {
        setError(error.message);
        navigate('/login')
      }
      //subscription details
      try {
        const token = localStorage.getItem('token'); // Get the token from localStorage
        const city2 = localStorage.getItem('city'); // Get the token from localStorage
        //console.log(city2);
        const response = await fetch(`${process.env.REACT_APP_API_URL}get-sub-detail/${city2}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
       
        
        const result = await response.json();
        //console.log(result);
        
        //setData(result);

        if(result.status === false){

          setMessage(result.message);
          setCode(result.code);
          setSubscribe(
            <>
              <Link to="../subscribe" className="btn btn-light btn-sm">Subscribe</Link>
            </>
          );
          setStatus(
            <>
            <p className="badge badge-danger">No Subscription</p>
            </>
          );
          return;

          

        }
        else if (result.status === true){
        
        const dateObject = new Date(result.data.expire_date);
        const expireDate = dateObject.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,  // Use 'false' for 24-hour format
        });

          setMessage (
            <>
            <p>Package:{result.data.sub_type} -- Expires: {expireDate} {formattedTime} </p>
            </>
          );
          const today = new Date(); // Get the current date as a Date object
          const expire = new Date(result.data.expire_date); // Convert the expire_date to a Date object

          if (expire < today) {
            setStatus(
              <>
              <p className="badge badge-danger">Expired</p>
              </>
            );
            setSubscribe(
              <>
                <Link to="../subscribe" className="btn btn-light btn-sm">Subscribe</Link>
              </>
            );
          } else if (expire > today) {
            setStatus(
              <>
              <p className="badge badge-success">Active</p>
              </>
            );
            setSubscribe(
              <>
                <Link to="../subscribe" className="btn btn-success btn-sm">Active</Link>
              </>
            );
          }else{

            setSubscribe(
              <>
                <Link to="../subscribe" className="btn btn-light btn-sm">Subscribe</Link>
              </>
            );

          }

        }
        
         
      } catch (error) {
        setError(error.message);
        navigate('/login')
      }

      
      
      //get station category
      
        
      };
      
      // Example usage
      
      
      const processData = (data) => {
        // Do some processing on the data
        return data; // Return processed data
      };
      

      
      //list fuel station
      

      
    

    fetchData();

    

    // Set up the interval to refresh data every 60 seconds (60000 milliseconds)
    const intervalId = setInterval(fetchData, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
  
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  
  
  return (
 <>
 <Header />
 <pre></pre>
 <pre></pre>
 
 <div class="loader-wrapper"><div class="lds-ring">
  <div className="" style={{ textAlign: 'left',  }}>
    <div className="container-fluid">
      <div className="row row-group m-0">
      <div className="col-12 col-lg-6 col-xl-6 border-light">
      
        <br />
        <div className="card-body" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  
            <div style={{ color: 'black', marginBottom: '16px' }}>
              Welcome {user.firstname} 
              <span className="float-right" style={{ fontSize: "12px" }}>Current City: {city2}</span>
            </div>
            <h5 style={{ color: 'black', marginBottom: '16px' }}>
              ₦ {wallet.balance} (Wallet)
              <span className="float-right">
                <Link to="/switch-city" className="btn btn-light btn-sm" style={{ backgroundColor: '#D4AF37'}}>Switch City</Link>
              </span>
            </h5>
            <div className="progress my-3" style={{ height: "3px" }}>
              <div className="progress-bar" style={{ width: "99%", backgroundColor: '#D4AF37' /* Light Gold */ }}></div>
            </div>
            
           
            <p style={{ color: 'black', marginBottom: '16px' }}>{point.balance} Point(s) </p>
           
            <p className="mb-0 small-font" style={{ color: 'black' }}>
              Your Account: {wallet.virtual_account_name} {wallet.virtual_account_number} {wallet.virtual_bank_name}
            </p>
           
            <p className="mb-0 small-font" style={{ color: 'black' }}>
              <b style={{ color: '#D4AF37' /* Light Gold */ }}>Referral Code: {user.username}</b>, 
              <span> Earn ₦300 per referral </span>
              <i>
              <button  className="btn btn-default btn-sm"
                onClick={handleCopyAccountNumber}
                
              >
                Copy Acc No.
              </button></i>
            </p>
           
        </div>

      </div>
      
      <div className="col-12 col-lg-6 col-xl-6 border-light">
        <br />
        <div className="card-body" style={{ backgroundColor: 'black', borderRadius: '10px', padding: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <h5 className="text-white mb-0">Subscription<span className="float-right">{subscribe}</span></h5>
            <div className="progress my-3" style={{ height: "3px" }} >
               <div className="progress-bar" style={{ width:"99%" }}></div>
            </div>
            <div>
          <p className="mb-0 text-white small-font">{message} <span className="float-right"><i className="zmdi zmdi-long-arrow-up"> {code === 422 ? 'Subscribe to have access to Premium Fuel Station, E.g NNPC, Bovas, Matrix, etc' : '' } </i></span></p>
          <span> {status}</span>
          </div>
        </div>
      </div>
    </div>
      <div className="row mt-3">
       
        <div className="col-lg-12">
           <div className="card">
            <div className="card-body">

            <button className="btn btn-light btn-sm" onClick={getLocation}>Refresh To Get Station Around You</button>
            
            <ul className="nav nav-tabs nav-tabs-primary top-icon nav-justified">
                <li className="nav-item">
                    <a href="#" data-bs-target="#profile" data-bs-toggle="pill" className="nav-link active"><i className="bi bi-fuel-pump"></i> <span className="hidden-xs">Fuel Station</span></a>
                </li>
                <li className="nav-item">
                    <a href="#"data-bs-target="#messages" data-bs-toggle="pill" className="nav-link"><i className="bi bi-fuel-pump-diesel"></i> <span className="hidden-xs"  >Disel Station</span></a>
                </li>
                <li className="nav-item">
                    <a href="#" data-bs-target="#edit" data-bs-toggle="pill" className="nav-link"><img src="assets/images/gas.png" width="20px" height="40px" /><span className="hidden-xs">Gas Station</span></a>
                </li>
            </ul>
            <div className="tab-content p-3">
                <div className="tab-pane active" id="profile">
                    <h5 className="mb-2">Petrol Station</h5>

                {petrols.map(petrol => (
                  
                  <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                        <span className="float-left badge badge-light">Pr </span><span className="float-right">₦{(petrol.fuel_price.toLocaleString())}</span>
                        <h5 className="text-white mb-0"> {petrol.station_name} </h5> 
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${petrol2.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{petrol2.fuel_price}/{petrol2.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{petrol.station_address} </p>
                          {petrol.latitude && petrol.longitude ? (
                                <p className="mb-0 text-white small-font">
                                    Distance: {(petrol.distance).toFixed(2)} km
                                </p>
                            ) : null}
                          <span className={`badge ${petrol.fuel_status === 'Available' ? 'badge-success' : 'badge-danger'}`}>{petrol.fuel_status}</span>
                          <span className="badge badge-primary" style={{backgroundColor: "black"}}> Updated {new Date(petrol.update_date).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              
                            })} </span>
                          <br />
                          <span  className="float-left"><Link to={`../view-fuel-station/${petrol.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                         
                        </div>
                        <hr />
                    </div> 
                    
                </div>
                   ))}

                {petrols2.map(petrol2 => (
                  
                  <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                        <span className="float-right">₦{petrol2.fuel_price.toLocaleString()}</span>
                          <h6 className="text-white mb-0">{petrol2.station_name} </h6>
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${petrol2.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{petrol2.fuel_price}/{petrol2.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{petrol2.station_address} </p>
                          <p className="mb-0 text-white small-font">
                            Distance: {(petrol2.distance / 1000).toFixed(2)} km
                          </p>
                          <span className={`badge ${petrol2.fuel_status === 'Available' ? 'badge-success' : 'badge-danger'}`}>{petrol2.fuel_status}</span>
                          <span className="badge badge-primary" style={{backgroundColor: "black"}}> Updated {new Date(petrol2.update_date).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              
                            })} </span>
                            <br />
                          <span  className="float-left"><Link to={`../view-fuel-station/${petrol2.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                          
                        </div>
                       
                    </div> 
                    <hr />
                </div>
                
                 ))}
                    
                </div>
               
                <div className="tab-pane" id="messages">
                  <h5 className="mb-2">Disel Station</h5>
                    <div className="row">
                      
                {diesels.map(diesel => (
                  
                  <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                          <h6 className="text-white mb-0">{diesel.station_name} </h6>
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${diesel.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{diesel.fuel_price}/{diesel.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{diesel.station_address} </p>
                          <span className="badge badge-primary"> Open {diesel.opening}</span>
                          <span className="badge badge-danger" style={{backgroundColor: "black"}}> Closes {diesel.closing}</span>
                          
                          <span  className="float-left"><Link to={`../view-fuel-station/${diesel.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                          
                        </div>
                       
                    </div> 
                    <hr />
                </div>
                 ))}

              {diesels2.map(diesel2 => (
                
                <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                          <h6 className="text-white mb-0">{diesel2.station_name} </h6>
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${diesel2.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{diesel2.fuel_price}/{diesel2.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{diesel2.station_address} </p>
                          <span className="badge badge-primary"> Open {diesel2.opening}</span>
                          <span className="badge badge-danger" style={{backgroundColor: "black"}}> Closes {diesel2.closing}</span>
                          <hr />
                          <span  className="float-left"><Link to={`../view-fuel-station/${diesel2.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                          
                        </div>
                    </div> 

                </div>
               ))}
                    </div>
                </div>
                <div className="tab-pane" id="edit">
                  <h5 className="mb-2">Gas Station</h5>
                    <div className="row">
                      
                {gases.map(gas => (
                  
                  <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                          <h6 className="text-white mb-0">{gas.station_name} </h6>
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${gas.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{gas.fuel_price}/{gas.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{gas.station_address} </p>
                          <span className="badge badge-primary"> Open {gas.opening}</span>
                          <span className="badge badge-danger" style={{backgroundColor: "black"}}> Closes {gas.closing}</span>
                          <hr />
                          <span  className="float-left"><Link to={`../view-fuel-station/${gas.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                          
                        </div>
                    </div> 

                </div>
                 ))}

              {gases2.map(gas2 => (
                
                <div className="row row-group" style={{ marginRight:"-40px", marginLeft:"-40px" }}>
                    <div className="col-12 col-lg-9 col-xl-12 border-light" >
                        <div className="card-body">
                          <h6 className="text-white mb-0">{gas2.station_name} </h6>
                            <div className="progress my-3" style={{ height:"3px" }}>
                               <div className="progress-bar" style={{ width:"99%" }}></div>
                            </div>
                          {/* <p className="mb-0 text-white small-font"><a href={`${gas2.map_link}`}><button  className="badge btn btn-light btn-sm" >Get Direction</button></a><span className="float-right">₦{gas2.fuel_price}/{gas2.price_unit}</span></p> */}
                          <p className="mb-0 text-white small-font">{gas2.station_address} </p>
                          <span className="badge badge-primary"> Open {gas2.opening}</span>
                          <span className="badge badge-danger" style={{backgroundColor: "black"}}> Closes {gas2.closing}</span>
                          <hr />
                          <span  className="float-left"><Link to={`../view-fuel-station/${gas2.id}/${user.id}`}  className="btn btn-light btn-sm" >View More Details</Link></span>
                          
                        </div>
                    </div> 

                </div>
               ))}
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  </div>  
  </div>  
  </div>
  </div>
    </>
  )
}

export default (HomePage);
