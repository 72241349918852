import React from "react"
import Navigation from "../components/Navigation"
import "../Styles/Dialogue.css"
import "../Styles/Switch.css"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constant/ApiConstant';
import axios from 'axios'
import  { useEffect, useState } from 'react';
import {useParams, useNavigate } from 'react-router-dom';


//import Counter from "../components/Counter"
//import Projects from "../components/Projects"
//import TestimonialSlider from "../components/TestimonialSlider"

 function StationDashboard() {

  const [user, setData] = useState(null);
  const [stations, setStation] = useState([]);
  const [categories, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete2, setItemToDelete2] = useState(null);
  const [itemToView, setItemToView] = useState(null);
  const [view, setView] = useState(null);
  const [wallet, setWallet] = useState('');
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const { id } = useParams();

  const [isDialogOpenView, setIsDialogOpenView] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [loadingId, setLoadingId] = useState(null); // Track which item is loading
  

  
    

  //console.log(process.env.REACT_APP_IMAGE_URL);
 //  setUrl("https://api.locatefuel.com/storage/");

  const navigate = useNavigate();
  const handleLinkClick = () => {
    // Store the current scroll position
    const scrollPosition = window.scrollY;
   
    localStorage.setItem('scrollPosition', scrollPosition);
    
  };
  

  const savedScrollPosition1 = localStorage.getItem('scrollPosition');
      if (savedScrollPosition1) {
        window.scrollTo(0, parseInt(savedScrollPosition1, 10));
        //localStorage.removeItem('scrollPosition'); // Optional: Clean up
      }


    const fetchData = async () => {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const user_role = localStorage.getItem('user_role'); // Get the token from localStorage
      //console.log(token);
      if(user_role === 'customer'){

        navigate('/dashboard');
        return;
      }
      if (!token) {
        
        //setError('No token found');
        navigate('/logout')
        return;
      }
      
      

      try {
        const response2 = await fetch(`${process.env.REACT_APP_API_URL}get-station-category/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (!response2.ok) {
          navigate('/logout')
        }
        
        
        const result2 = await response2.json();

        //const station_logo = result2.data.station_logo;
        //console.log(station_logo);
        //setStation("https://api.locatefuel.com/storage/");

        

       // console.log(`https://imagelink.com/${station_logo}`);
        
        
        setStation(result2.data);
       

        //const station_logo = result2.data.station_logo;
        // const station_banner = data.data.station_banner;
       
       return;
         
      } catch (error) {
        setError(error.message);
        navigate('/logout')
      }

      
      
     
      
    };

   

    useEffect(() => {
      fetchData();
    }, []);
  
  //delete category
  //const token = localStorage.getItem('token'); // Get the token from localStorage
  const handleDelete2 = () => {
    
    if (itemToDelete2) {
      //console.log(itemToDelete2);
      const token = localStorage.getItem('token'); // Get the token from localStorage
      fetch(`${process.env.REACT_APP_API_URL}delete-category/${itemToDelete2}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response2 => {
          if (response2.ok) {
            //console.log(category);
            navigate('');

            setCategory(categories.filter(category => category.id !== itemToDelete2));
          } else {  
            console.error('Failed to delete item');
          }
        })
        .catch(error => console.error('Error deleting item:', error))
        .finally(() => {
          setIsDialogOpen2(false);
          setItemToDelete2(null);
        });
    }
  };

  const openDeleteDialog2 = (category) => {
    setItemToDelete2(category);
    setIsDialogOpen2(true);
  };

  const closeDialog2 = () => {
    setIsDialogOpen2(false);
    setItemToDelete2(null);
  };

  const handleView = () => {
    
    
  };

  const openViewDialog = (station) => {

    
   // if (itemToView) {
      //console.log(itemToView);
      //console.log(station);
      const token = localStorage.getItem('token'); // Get the token from localStorage
      fetch(`${process.env.REACT_APP_API_URL}get-station-detail/${station}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response3 => {
          if (response3.ok) {
           // console.log(response);
           // navigate('');
          
           setView(response3.data);

           console.log(view);
          
           
          } else {  
            console.error('Failed to display items');
          }
        })
        .catch(error => console.error('Error displaying item:', error))
        .finally(() => {
          setIsDialogOpenView(false);
          
        });
  //  }
    
    setIsDialogOpenView(true);
  };

  const closeDialogView = () => {
    setIsDialogOpenView(false);
    
  };
 //use for checked switch
 const handleToggle = async (id2, newAvailability) => {
  setLoadingId(id2); // Set the loading state for the current item
  
  const scrollPosition = window.scrollY;
   
  localStorage.setItem('scrollPosition', scrollPosition);
//console.log(itemId);

  try {
    // Convert boolean to 1 (true) or 0 (false)
    const availabilityValue = newAvailability === "Available" ? "Not Available" : "Available";
    //console.log(availabilityValue);
    // Send a request to your API to update the availability status for the specific item
    const token = localStorage.getItem('token'); // Get the token from localStorage
    const response = await fetch(`${process.env.REACT_APP_API_URL}update-fuel-status/${id2}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ available: availabilityValue }) // Send 1 or 0 to the API
    });

    if (!response.ok) {
      throw new Error('Failed to update status');
    }
    if(response.ok){
     //window.location.reload();
     await fetchData();
     //navigate(`/station-details/${id}`)
    }
    // Update the item availability in the state
    setCategory(prevItems =>
      prevItems.map(category =>
        category.id === id2 ? { ...category, fuel_status: availabilityValue } : category
      )
    );
    
  } catch (error) {
    console.error('Error updating availability:', error);
    setError('Could not update availability. Please try again.');
  } finally {
    setLoadingId(null); // Reset loading state
  }  
};

  //view wallet details
  
  if (error) {
    return <div>{error}</div>;
  }

  
  
  

  return (
 <>
 <Header />
 
 <pre></pre>
 <pre></pre>
 
  <div className="" style={{ alignItems: 'center'}}>
  
  
  
    <div className="container-fluid ">
      
      
      <div className="row mt-3">
     
      {stations.map(station => (
      
        <div className="col-12 col-lg-6 col-xl-6 border-light">
        <div className="card-body" >
          <div className="card-content">
             <div className="card profile-card-2 col-lg-6 col-xl-12" >
              <div className="card-img-block">
                  <img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}/${station.station_banner}` || "https://via.placeholder.com/800x500"} />
              </div>
              <div className="card-body pt-5">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}/${station.station_logo}` || "https://via.placeholder.com/800x500"} alt="profile-image" className="profile" style={{ width: '100px', height: '75px' }} />
                  <h5 className="card-title" style={{ textTransform: 'capitalize' }}>{station.station_name} - {station.station_address} - {station.location}  </h5>
                  <span className="float-right"><Link to={`../upload-station-banner/${station.id}`} className="btn btn-light btn-sm">Update Banner</Link></span><span></span>
                  <span className="float-right"><Link to={`../update-fuel-station/${station.id}`} className="btn btn-success btn-sm" >Edit</Link></span>
              
                 
                  <button className="btn btn-light btn-sm" onClick={() => openViewDialog(station.id)}>View</button>
                  
              </div>
            </div>
          </div>
      </div>
        
      {isDialogOpenView && (
        <div className="dialog">
          <div className="dialog-content">
            <p className="" style={{color: 'black'}} > Details</p>
            <p className=""> Details</p>
            <button className="btn btn-success btn-sm" onClick={closeDialogView}>Cancel</button>
          </div>
        </div>
      )}
      
      

        <div className="col-lg-12">
           <div className="card">
            <div className="card-body">
            
               
                    <a href="#" data-bs-target="#profile" data-bs-toggle="pill" className="nav-link active"><i className="bi bi-fuel-pump"></i> <span className="hidden-xs">Cateory for {station.station_name} </span></a>
              

                    <span className="float-right"><Link to={`../fuel-category/${station.id}`} className="btn btn-light btn-sm" >+Add Fuel Category</Link></span><span></span>
             {station.fuel_category.map(category => ( 
                
            <div className="tab-content p-2">
                <div className="tab-pane active" id="profile">
                  
                    <h5 className="mb-2">{category.fuel_type}</h5>

                    <div className="row row-group" >
                      <div className="col-12 col-lg-12 col-xl-12 border-light">
                          <div className="card-body" style={{ marginRight:"-20px", marginLeft:"-20px" }}  >
                            <h5 className="text-white mb-0">{category.fuel_type} <span className="float-right"><i className="fa fa-power-off" style={{background_color: "red"}}> {category.fuel_status}</i></span></h5>
                              <div className="progress my-3" style={{ height:"3px" }}>
                                 <div className="progress-bar" style={{ width:"99%" }}></div>
                              </div>
                            <p className="mb-0 text-white small-font" style={{ textTransform: 'capitalize' }}>Rep: {category.station_rep} <span className="float-right">₦{category.fuel_price}/{category.price_unit}</span></p>
                            <p className="mb-0 text-white small-font">Phone: {category.phone_number}  <span className="float-right">Status: {category.activate_status}</span> </p>
                          </div>
                          <button className="btn btn-success btn-sm"><Link to={`../update-fuel-category/${category.id}`} className="">Edit</Link></button>
                          <button className="btn btn-danger btn-sm float-right" onClick={() => openDeleteDialog2(category.id)}>Delete</button><span style={{paddingRight: '5px'}}></span>
                          {/* <button className="btn btn-danger btn-sm" onClick={() => openDeleteDialog2(category.id)}>Delete</button><span style={{paddingRight: '5px'}}></span> */}
                          
                      </div>
                      
                  </div>   
                </div>
                <br />
                       
                            <div  className="availability-switch" id={`availability-switch-${category.id}`}>
                              
                              <label className="switch">
                              <input
                                  type="checkbox"
                                  checked={category.fuel_status === 'Available'} // Check if the status is 'Available'
                                  onChange={() => handleToggle(category.id, category.fuel_status)}
                                  disabled={loadingId === category.id} // Indicate which item is loading
                                />
                                <span className="slider"></span>
                              </label>
                              <div>{category.fuel_status}</div>
                            </div>
                         
                    <div>
                     
    </div>
                <br />
                <hr />
                {isDialogOpen2 && (
                <div className="dialog-category">
                  <div className="dialog-content-category">
                    <p className="" style={{color: 'black'}} >Are you sure you want to delete {category.fuel_type}?</p>
                    <button className="btn btn-danger btn-sm" onClick={handleDelete2}>Yes, Delete</button>
                    <button className="btn btn-success btn-sm" onClick={closeDialog2}>Cancel</button>
                  </div>
                </div>
      )}
                
            </div> 

            ))}

        </div>
      </div>
      </div>
      </div>
  
    ))}
    </div>
   
  </div>
  
  </div>  


   
  
    </>
  )
}

export default (StationDashboard);
